define("discourse/plugins/discourse-subscriptions/discourse/templates/admin/plugins-discourse-subscriptions-plans-index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <table class="table discourse-patrons-table">
    <thead>
      <th>{{i18n "discourse_subscriptions.admin.plans.plan.plan_id"}}</th>
      <th>{{i18n "discourse_subscriptions.admin.plans.plan.nickname.title"}}</th>
      <th>{{i18n "discourse_subscriptions.admin.plans.plan.interval"}}</th>
      <th>{{i18n "discourse_subscriptions.admin.plans.plan.amount"}}</th>
      <th></th>
    </thead>
    <tbody>
      {{#each this.model as |plan|}}
        <tr>
          <td>{{plan.id}}</td>
          <td>{{plan.nickname}}</td>
          <td>{{plan.interval}}</td>
          <td>{{plan.unit_amount}}</td>
          <td class="td-right">
            <DButton
              @action={{action "editPlan" plan.id}}
              @icon="far-edit"
              class="btn no-text btn-icon"
            />
            <DButton
              @action={{route-action "destroyPlan"}}
              @actionParam={{plan}}
              @icon="trash-alt"
              class="btn-danger btn no-text btn-icon"
            />
          </td>
        </tr>
      {{/each}}
    </tbody>
  </table>
  */
  {
    "id": "X+WMbQv0",
    "block": "[[[10,\"table\"],[14,0,\"table discourse-patrons-table\"],[12],[1,\"\\n  \"],[10,\"thead\"],[12],[1,\"\\n    \"],[10,\"th\"],[12],[1,[28,[35,3],[\"discourse_subscriptions.admin.plans.plan.plan_id\"],null]],[13],[1,\"\\n    \"],[10,\"th\"],[12],[1,[28,[35,3],[\"discourse_subscriptions.admin.plans.plan.nickname.title\"],null]],[13],[1,\"\\n    \"],[10,\"th\"],[12],[1,[28,[35,3],[\"discourse_subscriptions.admin.plans.plan.interval\"],null]],[13],[1,\"\\n    \"],[10,\"th\"],[12],[1,[28,[35,3],[\"discourse_subscriptions.admin.plans.plan.amount\"],null]],[13],[1,\"\\n    \"],[10,\"th\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"td\"],[12],[1,[30,1,[\"id\"]]],[13],[1,\"\\n        \"],[10,\"td\"],[12],[1,[30,1,[\"nickname\"]]],[13],[1,\"\\n        \"],[10,\"td\"],[12],[1,[30,1,[\"interval\"]]],[13],[1,\"\\n        \"],[10,\"td\"],[12],[1,[30,1,[\"unit_amount\"]]],[13],[1,\"\\n        \"],[10,\"td\"],[14,0,\"td-right\"],[12],[1,\"\\n          \"],[8,[39,9],[[24,0,\"btn no-text btn-icon\"]],[[\"@action\",\"@icon\"],[[28,[37,10],[[30,0],\"editPlan\",[30,1,[\"id\"]]],null],\"far-edit\"]],null],[1,\"\\n          \"],[8,[39,9],[[24,0,\"btn-danger btn no-text btn-icon\"]],[[\"@action\",\"@actionParam\",\"@icon\"],[[28,[37,11],[\"destroyPlan\"],null],[30,1],\"trash-alt\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"plan\"],false,[\"table\",\"thead\",\"th\",\"i18n\",\"tbody\",\"each\",\"-track-array\",\"tr\",\"td\",\"d-button\",\"action\",\"route-action\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/templates/admin/plugins-discourse-subscriptions-plans-index.hbs",
    "isStrictMode": false
  });
});